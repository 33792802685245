export const environment = {
    production: false,
    // API_URL: 'https://localhost:7219/api/v1/',
    // API_URL: 'https://bishopsbrokerageapi.azurewebsites.net',
    API_URL: 'https://brokerage-api.azurewebsites.net',
    REPORT_KEY: "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkps1BjULK3x5f+alxC4Q8QTL3zatUoiXWPadUv8wYdxaCwPk" +
        "dDAzsO7UQwHeYjbvxBVgH4P8ApJaakn8BGM7HY8V69jHp5iTsTKWU+n4NwyJAesnS+EJP+1y1/k62ZDs6/NqsnAQB4" +
        "U0Tg86X0rhJBTOmfRdiPYtmLLUFdIRha8iqDtSAgRrlASOTXDQ/yK3j8l3TmQdcocTarV7d0SmYrz6Nn9PPwzZU+RB" +
        "1JPe/Kgc49t+joPXVLNkG+AZc93FQu7Wteuj2eMgs9mWE4qr7oJv+6V8LnNovFXoNg7vY70q4FknhXBUYs4wEmkwvr" +
        "DWVfFMy09lvUGKdKxgfhPHLHp+fSHnHKh3FHQ2rm1u+eqfKYzfdwAKXYTIIsHXThzsjgy1BsBxl7mkwNoU0KgAj+kH" +
        "JBuTVVpbjfG2hdQsHcSpMkhy/+0SBmHAAeVTpSHnFesHm+PVDb+B6bp3oRbcP7uxOTOzJ3wv2RZPToLE9nvlGaoH2A" +
        "ntTfRDhabh3j8hCt8LGWx8ex1nGq/BEfj//sJ1boRcOUoizX9JGzxKLNPDkgXvv0otycELzB4r1dr4DzFIHwLIWi1W" +
        "CYNjRrCw6Z25kzsUs/vUvHEv3gdb29v0903OdemFN6AfJ/wbNzkGqr9aF+7LrtCs2r/ONpMz/9"
};
